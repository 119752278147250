import React from 'react';
import Hero from '../component/Hero'
import Sec from '../component/Sec'

function Main() {
    return (
        <>
        <Hero />
        <Sec />
        </>
    )
}
export default Main;